import React, {Fragment} from 'react';
import PreBookingInfo from "../Elements/PreBookingInfo";
import {BookingData} from "../Data/BookingData";
import TypeSelect from "../Elements/TypeSelect";
import BookingComplete from "../Elements/BookingComplete/BookingComplete";
import TimeSelect from "../Elements/TimeSelect/TimeSelect";
import PreOrder from "../Elements/PreOrder/PreOrder";
import UserData from "../Elements/UserData";
import CompleteBooking from "../Elements/CompleteBooking";
import Button from "../../Form/Element/Button";
import BookingStep from "../../Utility/BookingStep/BookingStep";
import Notice from "../../Utility/Notice/Notice";
import DepositBar from "../Elements/Deposit/DepositBar";
import TermsText from "../Elements/TermsText";
import {Settings} from "../../Settings/Settings";
import moment from "moment";
import ShortSummary from "../Elements/ShortSummary/ShortSummary";

export default function DrinksBooking(props) {
    let booking = props.booking;
    let fallback = <Fragment>
        <BookingStep step={99} name="user-data" type="masterclass" hot_jar_url="enquiry-error" booking={props.booking}>
            <UserData booking={props.booking}
                      include_pre_booking={<PreBookingInfo booking={props.booking} updating={props.updating}
                                                           minDate={moment(props.booking.min_booking_date).toDate()}
                                                           openToDate={BookingData.getOpenToDate()} include_booking_time={true}
                      />}
                      additional_info={<p>Cool, so just fill in your details below and one of our team will get in touch with you as soon as they can via phone or email to talk business (joking, to talk party!)</p>}/>
            <TermsText booking={props.booking}/>
        </BookingStep>
        <DepositBar wet={true} booking={props.booking} updating={props.updating} next_step={props.next_step}
                    skip_text="Send Enquiry" can_skip={props.booking.can_complete}
                    continueFunction={() => BookingData.completeBooking()}/>
    </Fragment>
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking} show_end_time={booking.step < 99}/>
    }
    if (booking.step === 1) {
        let can_proceed = props.booking.can_take_booking;
        ['no_people'].map((field) => {
            if (BookingData.getFieldError(field)) {
                can_proceed = false;
            }
            return field;
        });
        return <BookingStep step={1} type="drinks" name="pre-booking-info" hot_jar_url="enquiry-info" booking={props.booking}>
            <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({'step': 2})} disabled={!can_proceed} name="continue"/>}/>
        </BookingStep>
    }
    if (booking.step === 2) {
        return <BookingStep step={2} type="drinks" name="time-select" hot_jar_url="enquiry-availability-check" booking={props.booking}>
            <PreBookingInfo booking={props.booking} updating={props.updating}
                            minDate={moment(props.booking.min_booking_date).toDate()}
                            openToDate={BookingData.getOpenToDate(true)}
            />
            {props.booking.date !== null && <TimeSelect booking={props.booking} updating={props.updating} show_price={true} stacked={true} allow_fallback={true} fallback_form={fallback}
                        show_alternative={true} unavailable_message="Sorry, looks like we are pretty full that day. Do you want to try a different day?"/>}
        </BookingStep>
    }

    if (booking.step === 3) {
        let sub_text = '';
        if (booking.min_spend !== null && booking.min_spend > 0) {
            sub_text = <Fragment>But because this time is really popular we'll need you to spend {Settings.currencyFormatter.format(booking.min_spend)} to secure the table. Just pick from the options below and we'll have these delivered straight to your table on arrival!</Fragment>
        }
        return <Fragment>
            <BookingStep step={3} type="drinks" name="pre-order" hot_jar_url="enquiry-pre-orders" booking={props.booking}>
                <PreOrder booking={props.booking}
                          updating={props.updating}
                          intro_text="A Table is available at this time, would you like to pre-order anything?"
                          sub_text={sub_text} can_skip={true}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text="Continue" can_skip={props.booking.can_last_step} continue={true}
                        continueFunction={() => {
                            BookingData.update({step: 4});
                        }}/>
        </Fragment>
    }
    if (booking.step === 4) {
        return <BookingStep step={4} type="drinks" name="user-data" hot_jar_url="enquiry-personal-details" booking={props.booking}>
            <ShortSummary booking={props.booking} show_end_time={true}/>
            <UserData booking={props.booking}/>
            <TermsText booking={props.booking}/>
            <CompleteBooking booking={props.booking}/>
        </BookingStep>
    }

    if (booking.step === 99) {
        return fallback
    }
    return <BookingStep step={999} name="no-step" type="drinks" booking={props.booking}>
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>
}