import React, {Fragment} from 'react';
import {Settings} from "../../../Settings/Settings";
import moment from "moment/moment";

export default function DepositInfo(props) {
    let booking_date = new moment(props.booking.booking_date)
    let balance_due_date = new moment(props.booking.balance_due_date)

    return <div>
        A {Settings.currencyFormatter.format(props.booking.deposit)} deposit is required to confirm this booking.
        {booking_date.isValid() && balance_due_date.isValid() && balance_due_date.isBefore(booking_date) && <Fragment>
            &nbsp; The remaining balance will then be due prior to your booking date.
        </Fragment>}
    </div>
}