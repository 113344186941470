import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import './ChooseCallback.scss';
import moment from 'moment';
import {EventDispatcher} from "../../../Events/Dispatcher";
import SelectCallback from "./SelectCallback";

export default function ChooseCallback(props) {
    let time = props.booking.callback_date + ' ' + props.booking.callback_time + ':00';
    let slot = props.booking.callback_slots.data.find((slot) => {
        return slot.date === time;
        let date = moment(slot.date);
        let end = moment(date.format('YYYY-MM-DD') + ' ' + slot.end + ':00:00');
        return moment(time).isSameOrAfter(date) && moment(time).isSameOrBefore(end);
    });
    if (!slot) {
        return <span/>
    }
    let day = moment(props.booking.callback_date).format('DD/MM/YYYY');
    if (day === moment().format('DD/MM/YYYY')) {
        day = 'Today';
    } else if (day === moment().add(1, 'day').format('DD/MM/YYYY')) {
        day = 'Tomorrow';
    }
    if (props.booking.bar.data.covid_closed === true) {
        return <Fragment>
            <div className="choose-callback">
                <p>For this type of enquiry we'll need to give you a call to confirm your booking. We'll be in contact
                    when we're back open to get you booked in!</p>
            </div>
        </Fragment>
    }

    return <Fragment>
        <div className="choose-callback">
            <p>For this type of enquiry we'll need to give you a call to confirm your booking.
                Your call back time
                is <strong>{day}</strong> between <strong>{slot.start}:00</strong> and <strong>{slot.end}:00</strong>
                <button className="change-callback link-button" onClick={() => EventDispatcher.dispatch('open-modal', {
                    content: <SelectCallback booking={props.booking}/>
                })}>Change
                </button>
            </p>
        </div>
    </Fragment>
}

ChooseCallback.propTypes = {
    booking: PropTypes.object.isRequired
}