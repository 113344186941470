import React, {useState, useEffect} from 'react'
import Button from './Button'

const DeleteConfirm = (props) => {
    const [removeText, setRemoveText] = useState('Remove');
    const [remove, setRemove] = useState(false);
    
    let timeout;
    let id = props.id
    useEffect(()=> {
        return function cleanup() {
            if (timeout) clearTimeout(timeout)
        }
    }, [])

    const handleClick = (id) => {
        if (remove === true) {
            props.onDelete(id);
            return;
        }
        setRemove(true)
        setRemoveText('Sure?')
        timeout = setTimeout(() => {
            setRemove(false)
            setRemoveText('Remove')
        }, 3000);
    }

    return (
        <Button id={props.id} variant="outline" name={props.name} onClick={()=> {handleClick(id)}}
        text={<><i className="fa fa-trash-o"/> {removeText}</>}/>
    )
}

export default DeleteConfirm;
