import React, {Fragment} from 'react';
import {BookingData} from "../Data/BookingData";
import FormElementTimeSelect from "../../Form/Element/FormElementTimeSelect";
import PropTypes from 'prop-types';
import './PreBookingInfo.scss'

export default function PreBookingInfo(props) {
    return <Fragment>
        {props.include_booking_time && <FormElementTimeSelect
            name="time"
            selectedValue={props.booking.time ? props.booking.time : ''}
            disabled={props.updating}
            blankOptionText="- Approx Start Time -"
            onChange={(e) => BookingData.update({time: e.target.value})}
            includeBlankOption={true}
        />}
    </Fragment>
}

PreBookingInfo.defaultProps = {
    include_booking_time: false,
}

PreBookingInfo.propTypes = {
    filterDate: PropTypes.func,
    minDate: PropTypes.object,
    openToDate: PropTypes.object,
    include_booking_time: PropTypes.bool
};