import React, {Fragment} from 'react';
import PreBookingInfo from "../Elements/PreBookingInfo";
import {BookingData} from "../Data/BookingData";
import TypeSelect from "../Elements/TypeSelect";
import BookingComplete from "../Elements/BookingComplete/BookingComplete";
import PreOrder from "../Elements/PreOrder/PreOrder";
import TimeSelect from "../Elements/TimeSelect/TimeSelect";
import UserData from "../Elements/UserData";
import DepositBar from "../Elements/Deposit/DepositBar";
import TermsText from "../Elements/TermsText";
import Button from "../../Form/Element/Button";
import BookingStep from "../../Utility/BookingStep/BookingStep";
import Notice from "../../Utility/Notice/Notice";
import ShortSummary from "../Elements/ShortSummary/ShortSummary";
import moment from "moment";
import {Settings} from "../../Settings/Settings";

export default function MasterclassBooking(props) {
    let booking = props.booking;
    let fallback = <Fragment>
        <BookingStep step={99} name="user-data" type="masterclass" hot_jar_url="enquiry-fallback-to-callback" booking={props.booking}>
            <UserData booking={props.booking}
                      additional_info={<Fragment>Cool, you want us to help you through your booking? Fill in your
                          details below and we'll get back to you asap!</Fragment>}
                      include_pre_booking={<PreBookingInfo
                          booking={props.booking}
                          updating={props.updating}
                          minDate={props.minDate}
                          openToDate={props.filterDate}
                          placeholder="Proposed date"
                          filterDate={props.filterDate}
                          include_booking_time={true}
                      />}
            />
            <TermsText booking={props.booking}/>
        </BookingStep>
        <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                    skip_text="Send Enquiry" can_skip={props.booking.can_last_step}
                    continueFunction={() => BookingData.completeBooking()}/>
    </Fragment>
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking}/>
    }
    if (booking.step === 1) {
        let can_proceed = props.booking.can_take_booking;
        ['no_people'].map((field) => {
            if (BookingData.getFieldError(field)) {
                can_proceed = false;
            }
            return field;
        });
        return <BookingStep step={1} name="pre-booking-info" type="masterclass" hot_jar_url="enquiry-info" booking={props.booking}>
            <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({'step': 2})} disabled={!can_proceed} name="continue"/>}/>
        </BookingStep>
    }
    if (booking.step === 2) {
        let unavailable_message = '';

        if (props.booking.date && props.booking.can_cater_for === false) {
            if (props.booking.no_people < 10) {
                unavailable_message = <Fragment>
                    <Notice name="cant-cater" variant="error">
                        <p>Sorry, looks like we are either pretty full on that day do
                            you want to submit an enquiry to see if we can squeeze you in?</p>
                    </Notice>
                </Fragment>
            } else {
                unavailable_message = <Fragment>
                    <Notice name="cant-cater" variant="error">Sorry, looks like we won't be able to cater for that size
                        automatically. We may be able to sort something if you submit an enquiry, would you like to do
                        that now?
                    </Notice>
                </Fragment>
            }
        }
        return <BookingStep step={2} name="pre-booking-info" type="time-select" booking={props.booking}
                            hot_jar_url="enquiry-availability-check">
            <PreBookingInfo booking={props.booking} updating={props.updating}
                            minDate={moment(props.booking.min_booking_date).toDate()} openToDate={BookingData.getOpenToDate()}/>
            {props.booking.date && <TimeSelect booking={props.booking} updating={props.updating} show_price={true}
                        unavailable_message={unavailable_message} stacked={true} fallback_form={fallback}/>}
        </BookingStep>
    }

    if (booking.step === 3) {
        let skip_text = 'Skip';
        if (props.booking.value > 0) {
            skip_text = 'Continue';
        }
        return <Fragment>
            <BookingStep step={3} name="pre-booking-info" type="time-select" hot_jar_url="enquiry-pre-orders" booking={props.booking}>
                <PreOrder booking={props.booking}
                          updating={props.updating}
                          intro_text="Sweet, what type of cocktail masterclass would you like?"
                          can_skip={true}
                          skip_text={skip_text}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text={'Continue'} can_skip={true}/>
        </Fragment>
    }
    if (booking.step === 4) {
        let skip_text = 'Save Booking';
        if (props.booking.deposit === 0) {
            skip_text = 'Complete Booking';
        }
        return <Fragment>
            <BookingStep step={4} name="user-data" type="masterclass" hot_jar_url="enquiry-personal-details" booking={props.booking}>
                <ShortSummary booking={props.booking}/>
                <UserData booking={props.booking}/>
                <TermsText booking={props.booking}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating} next_step={props.next_step}
                        skip_text={skip_text} can_skip={props.booking.can_complete}
                        continueFunction={() => BookingData.completeBooking()}/>
        </Fragment>
    }
    if (booking.step === 99) {
        return fallback
    }
    return <BookingStep step={999} name="no-step" type="masterclass" hot_jar_url="enquiry-error" booking={props.booking}>
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>
}