import React, {Fragment, useEffect, useState} from 'react'
import PreBookingInfo from '../Elements/PreBookingInfo'
import { BookingData } from '../Data/BookingData'
import TypeSelect from '../Elements/TypeSelect'
import BookingComplete from '../Elements/BookingComplete/BookingComplete'
import PreOrder from '../Elements/PreOrder/PreOrder'
import TimeSelect from '../Elements/TimeSelect/TimeSelect'
import UserData from '../Elements/UserData'
import moment from 'moment'
import DepositBar from '../Elements/Deposit/DepositBar'
import TermsText from '../Elements/TermsText'
import Button from '../../Form/Element/Button'
import BookingStep from '../../Utility/BookingStep/BookingStep'
import Notice from '../../Utility/Notice/Notice'
import ShortSummary from '../Elements/ShortSummary/ShortSummary'
import FallbackToCallbackButton from '../Elements/Callback/FallbackToCallbackButton'
import {Settings} from "../../Settings/Settings";

export default function BrunchBooking (props) {
    let booking = props.booking
    const [isFallbackFormOpen, setFallbackFormOpen] = useState(false)
    useEffect(() => {
        setFallbackFormOpen(false)
    }, [booking]);

    let fallback = <Fragment>
        <BookingStep step={99} name="fallback" type="brunch" booking={props.booking}
                     hot_jar_url="enquiry-fallback-to-callback">
            <UserData booking={props.booking}
                      additional_info={Settings.getEnquiryMessage()}/>
            <TermsText booking={props.booking}/>
        </BookingStep>
        <DepositBar booking={props.booking} updating={props.updating}
                    next_step={props.next_step}
                    skip_text="Send Enquiry" can_skip={props.booking.can_complete}
                    continueFunction={() => BookingData.completeBooking()}/>
    </Fragment>
    if (booking.is_completed === true) {
        return <BookingComplete booking={props.booking}
                                show_end_time={booking.step < 99}/>
    }
    if (booking.step === 1) {
        let can_proceed = props.booking.can_take_booking;
        ['no_people'].map((field) => {
            if (BookingData.getFieldError(field)) {
                can_proceed = false
            }
            return field
        })
        return <BookingStep step={1} name="pre-booking-info" type="brunch"
                            hot_jar_url="enquiry-info" booking={props.booking}>
            <TypeSelect booking={props.booking} updating={props.updating} nextButton={<Button text="Next" onClick={() => BookingData.update({ 'step': 2 })} disabled={!can_proceed} name="continue"/>}/>
        </BookingStep>
    }
    if (booking.step === 2) {
        let booking_date = moment(props.booking.date)
        booking_date = booking_date.add(booking_date.day(6), 'day')
        let message = 'Whoops! We don\'t serve brunch on ' +
          moment(props.booking.date).format('dddd') +
          ', do you want to check a different day?'
        let show_alternative = true
        if (booking.can_cater_for === true) {
            message = 'Oh dear! Looks like we are fully booked for brunch on this day. Do you want to try another day?'
        }
        if ((booking.alternative_dates.length === 0 && booking.time_slots.length ===
          0) || booking.can_cater_for === false) {
            return <Fragment>
                <BookingStep step={2} name="time-select" type="brunch" booking={props.booking}
                             hot_jar_url="enquiry-availability-none">
                    <Fragment>
                        <Notice name="no-brunch">
                            Oh dear! Looks like we are fully booked for brunch on this day.
                        </Notice>
                        {isFallbackFormOpen ? fallback : <Button variant="full-width" text="Let us ring you back to discuss further options" name="submit-enquiry" onClick={() => setFallbackFormOpen(true)}/>}
                    </Fragment>
                </BookingStep>
            </Fragment>
        }
        return <Fragment>
            <BookingStep step={2} name="time-select" type="brunch" booking={props.booking}
                         hot_jar_url="enquiry-availability-check">
                <PreBookingInfo booking={props.booking} updating={props.updating}
                                minDate={moment(props.booking.min_booking_date).toDate()}
                                openToDate={BookingData.getOpenToDate()}
                />
                <TimeSelect booking={props.booking} updating={props.updating}
                            show_price={true} stacked={true}
                            show_alternative={true} allow_fallback={false}
                            unavailable_message={message}/>
            </BookingStep>
        </Fragment>
    }

    if (booking.step === 3) {
        let skip_text = 'Skip'
        if (props.booking.value > 0) {
            skip_text = 'Continue'
        }
        return <Fragment>
            <BookingStep step={3} name="pre-order" type="brunch" booking={props.booking}
                         hot_jar_url="enquiry-pre-orders">
                <PreOrder booking={props.booking}
                          updating={props.updating}
                          intro_text={'Yay! We have a table for brunch available! Just pick our standard ' +
                            props.booking.party_type_name +
                            ' package below OR if you’re feeling really fancy, select the Premium version. Once you are ready, click \'Continue\' to find out how to confirm your booking.'}
                          can_skip={true}
                          skip_text={skip_text}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating}
                        next_step={props.next_step}
                        skip_text={'Continue'}
                        can_skip={props.booking.can_last_step}/>
        </Fragment>
    }
    if (booking.step === 4) {
        let skip_text = 'Save Booking'
        if (props.booking.deposit === 0) {
            skip_text = 'Complete Booking'
        }
        return <Fragment>
            <BookingStep step={4} name="user-data" type="brunch" booking={props.booking}
                         hot_jar_url="enquiry-personal-details">
                <h2>Almost Done</h2>
                <ShortSummary booking={props.booking} additional_class="desktop"
                              show_end_time={true}/>
                <UserData booking={props.booking}/>
                <ShortSummary booking={props.booking} additional_class="mobile"
                              show_end_time={true}/>
                <TermsText booking={props.booking}/>
            </BookingStep>
            <DepositBar booking={props.booking} updating={props.updating}
                        next_step={props.next_step}
                        skip_text={skip_text} can_skip={props.booking.can_complete}
                        continueFunction={() => BookingData.completeBooking()}/>
        </Fragment>
    }
    if (booking.step === 99) {
        return fallback
    }
    return <BookingStep step={999} name="no-step" type="brunch" booking={props.booking}
                        hot_jar_url="enquiry-error">
        <Notice name="no-step" variant="error">
            Woops something has gone wrong here..
        </Notice>
    </BookingStep>
}