import React from 'react';
import PropTypes from 'prop-types';
import './SelectCallback.scss';
import moment from 'moment';
import {BookingData} from "../../Data/BookingData";
import {EventDispatcher} from "../../../Events/Dispatcher";
import TimeSelectButton from "../../../Form/Element/TimeSelectButton";
import IntroText from "../../../Utility/IntroText/IntroText";

export default function SelectCallback(props) {
    let callback_slots = props.booking.callback_slots.data;
    let slots = callback_slots.slice(0, 12);
    return <div className="slot-holder choose-callback-time">
        <IntroText name="choose-time">Choose your time below, and we'll give you a call to discuss further.</IntroText>
        <div className="time-slot-holder clearfix">
            {slots.map((slot) => {
                let date = moment(slot.date);
                let day = date.format('dddd Do');
                if (date.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
                    day = 'Today';
                } else if (date.format('DD/MM/YYYY') === moment().add(1, 'day').format('DD/MM/YYYY')) {
                    day = 'Tomorrow';
                }
                let additional = 'between ' + slot.start + ':00 and ' + slot.end + ':00';
                if (slot.end === 17) {
                    additional = 'between ' + slot.start + ':00 and 16:45'
                }
                return <TimeSelectButton text={day}
                                         additional={additional}
                                         key={slot.date} onClick={() => {
                    BookingData.update({
                        callback_date: date.format('YYYY-MM-DD'),
                        callback_time: date.format('HH:mm')
                    }, () => {
                        EventDispatcher.dispatch('close-modal');
                    });
                }} active={true} stacked={true}/>
            })}
        </div>
    </div>
}

SelectCallback.propTypes = {
    booking: PropTypes.object.isRequired
}