import React, {Fragment, useState} from 'react';
import FormElementText from "../../Form/Element/Text";
import {BookingData} from "../Data/BookingData";
import ChooseCallback from "./Callback/ChooseCallback";
import OptIns from "./OptIn/OptIns";
import PropTypes from 'prop-types';
import _ from 'lodash';
import IntroText from "../../Utility/IntroText/IntroText";
import FormElementCheckbox from "../../Form/Element/Checkbox";
import './UserData.scss';
import Notice from "../../Utility/Notice/Notice";
import {Settings} from "../../Settings/Settings";

export default function UserData(props) {
    const [has_data, setData] = useState({
        forename: !!props.booking.forename,
        surname: !!props.booking.surname,
        email: !!props.booking.email,
        phone_number: !!props.booking.phone,
        allergies: props.booking.allergies || 4
    });

    const updateField = (field, value) => {
        let data = _.extend({}, has_data);
        data[field] = true;
        let update_data = {};
        update_data[field] = value;
        setData(data);
        BookingData.update(update_data);
    }
    const getError = (field) => {
        if (has_data[field] === false) {
            return '';
        }

        return BookingData.getValidationErrorMessage(field);
    };
    return <Fragment>
        {props.additional_info ? <IntroText name="user-data--message" title={props.additional_info}/> : null}
        {props.include_pre_booking}
        <FormElementText name="forename" value={props.booking.forename ? props.booking.forename : ''}
                         defferredUpdate={true}
                         error={getError('forename')}
                         placeholder="Forename"
                         onChange={(e) => updateField('forename', e.target.value)}/>
        <FormElementText name="surname" value={props.booking.surname ? props.booking.surname : ''}
                         defferredUpdate={true}
                         error={getError('surname')}
                         placeholder="Surname"
                         onChange={(e) => updateField('surname', e.target.value)}/>
        <FormElementText name="email" value={props.booking.email ? props.booking.email : ''}
                         placeholder="Email (Required)"
                         error={getError('email')}
                         defferredUpdate={true}
                         onChange={(e) => updateField('email', e.target.value)}/>
        <FormElementText name="phone_number"
                         placeholder="Phone Number (Required)"
                         value={props.booking.phone_number ? props.booking.phone_number.replace(/\D/g, '') : ''}
                         error={getError('phone_number')}
                         defferredUpdate={true}
                         number={true}
                         onChange={(e) => updateField('phone_number', e.target.value)}/>
        <OptIns booking={props.booking}/>
        {!props.booking.can_auto_confirm && !props.hide_callback ?
            <ChooseCallback booking={props.booking}/> : null}
    </Fragment>
}

UserData.defaultProps = {
    include_booking_date: false,
    include_booking_time: false,
}

UserData.propTypes = {
    hide_callback: PropTypes.bool,
    include_booking_date: PropTypes.bool,
    include_booking_time: PropTypes.bool,
    include_pre_booking: PropTypes.object
}