import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment';
import CreateAccount from "../CreateAccount/CreateAccount";
import {Settings} from "../../../Settings/Settings";
import './BookingComplete.scss';
import PayBooking from "../Payment/PayBooking";
import {BookingData} from "../../Data/BookingData";
import PaymentSummary from "../Payment/PaymentSummary";
import ShareBooking from "../ShareBooking/ShareBooking";
import Notice from "../../../Utility/Notice/Notice";
import Button from "../../../Form/Element/Button";
import BookingStep from "../../../Utility/BookingStep/BookingStep";
import Center from "../../../Utility/Center/Center";
import IntroText from "../../../Utility/IntroText/IntroText";
import TimeCountdown from "../../../Utility/TimeCountdown/TimeCountdown";

export default function BookingComplete(props) {
    let booking = props.booking.booking.data;
    const [payment, setPayment] = useState({
        type: '',
        response: null
    });
    if (!booking) {
        return <Fragment>
            <div className="error">This should not have happened...</div>
        </Fragment>
    }
    let title = 'Enquiry Added';
    let sub_text = 'We\'ve sent your details to our team and they will be in touch shortly to discuss your enquiry. Please note, this is not yet a confirmed booking and is dependent on availability.';
    if (props.booking.deposit > 0 && props.booking.step < 99) {
        title = 'Pay Now!';
        sub_text =
            <TimeCountdown time={booking.deposit_due_date} future_copy="Your spot is saved, you must pay within {time}"
                           past_copy="Your payment is now overdue by {time}. Pay now to secure your spot!"/>
    }
    if (booking.status_id === 1) {
        title = 'Booking Confirmed';
        if (Settings.bookingHasSelectablePreOrder(booking)) {
            sub_text = 'We\'ve emailed you confirmation of this booking along with a link to manage your booking and pre-order online. You can also hit the button below to go there now, see you soon!'
        } else {
            sub_text = 'We\'ve emailed you confirmation of this booking along with a link to manage your booking online. See you soon!';
        }
    }
    let show_deposit_button = booking.deposit_due_date && booking.status_id !== 1 && booking.status_id !== 4;
    let diff = null;
    let string = '';
    if (props.show_end_time === true) {
        diff = moment(props.booking.end_time).diff(props.booking.booking_date, 'minutes');
        if (diff > 60) {
            let hours = Math.floor(diff / 60);
            string = hours + ' hour' + (hours > 1 ? 's' : '') + ' ';
            diff = diff - (hours * 60);
        }
        if (diff > 0) {
            string = string + diff + ' minutes';
        }
    }
    let show_full = false, show_deposit = false;
    if (booking.deposit_due_date && booking.deposit_balance > 0) {
        show_deposit = true;
    }
    if (props.booking.full_payment_required && booking.deposit_balance > 0) {
        show_full = true;
        show_deposit = false;
    }
    return <BookingStep step="complete" type="complete" name="complete" hot_jar_name="enquiry-complete" booking={props.booking}>
        <IntroText name="booking-complete-sub-text" title={title}>{sub_text}</IntroText>
        <Notice name="booking-summary">
            <Fragment>
                <p>Reference Code: <br/><strong>{booking.ref_code}</strong></p>
                <p>Summary:<br/>
                    <strong>
                        {booking.bar.data ? booking.bar.data.branded_name : null}<br/>
                        <span>{booking.people} People
                        - {moment(booking.booking_date).format(Settings.date_formats.summary_full_date_time)}</span>
                        {props.show_end_time && <span><br />Your booking is for {string}</span>}
                    </strong>
                </p>
                {show_full ? <Fragment>
                    <p>Full Balance Required<br/>
                        <strong>
                            {Settings.currencyFormatter.format(props.booking.value)}
                            {moment(booking.balance_due_date).isBefore(moment()) ? ' due now' : <Fragment>
                                by {moment(booking.balance_due_date).format('DD/MM/YYYY')}</Fragment>}
                        </strong>
                    </p>
                </Fragment> : null}
                {show_deposit ? <Fragment>
                    <p>Deposit Required<br/>
                        <strong>{Settings.currencyFormatter.format(props.booking.deposit)} by {moment(booking.deposit_due_date).format('DD/MM/YYYY')}</strong>
                    </p>
                </Fragment> : null}

                {props.booking.variations.data.length > 0 && props.booking.step < 99 ? <p>
                    Options:<br/>
                    {props.booking.variations.data.map((variation) => {
                        return <Fragment
                            key={variation.variation_id}><strong>{variation.quantity} x {variation.name}</strong><br/></Fragment>
                    })}
                </p> : null}
            </Fragment>
        </Notice>
        {payment.type === 'deposit' ?
            <PayBooking code={booking.payment_token} type="deposit" booking={props.booking} onComplete={(data) => {
                BookingData.update({}, () => setPayment({
                    type: '',
                    response: data
                }), true);
            }}/> : null}
        {payment.type === 'balance' ?
            <PayBooking code={booking.payment_token} type="balance" booking={props.booking} onComplete={(data) => {
                BookingData.update({}, () => setPayment({
                    type: '',
                    response: data
                }), true);
            }}/> : null}
        {payment.response ? <Fragment>
            <PaymentSummary payment_data={payment.response}/>
        </Fragment> : null}
        {!payment.type ? <Fragment>
            {show_deposit_button ?
                <div className="deposit-buttons">
                    {props.booking.full_payment_required ? <Fragment>
                        {booking.balance > 0 ? <Button name=" deposit-button" onClick={() => setPayment({
                            type: 'balance',
                            'response': null
                        })} text={<Fragment>Pay Balance Now
                            - {Settings.currencyFormatter.format(booking.balance)}</Fragment>}/> : null}
                    </Fragment> : <>
                        {booking.deposit_balance > 0 ? <Button name="pay-deposit" onClick={() => setPayment({
                                type: 'deposit',
                                'response': null
                            })} text={<Fragment>Pay Deposit Now
                                - {Settings.currencyFormatter.format(booking.deposit_balance)}</Fragment>}/>
                            : null}
                        {booking.balance > 0 ? <Button name=" deposit-button" onClick={() => setPayment({
                            type: 'balance',
                            'response': null
                        })} text={<Fragment>Pay Balance Now
                            - {Settings.currencyFormatter.format(booking.balance)}</Fragment>}/> : null}
                    </>}
                </div> : null}
        </Fragment> : null}
        <CreateAccount customer={props.booking.customer.data} email={props.booking.email} token={booking.manage_key} button_text={props.booking.step === 99 ? 'Manage My Enquiry' : 'Manage My Booking'}/>
        <Center><ShareBooking booking={props.booking.booking.data}/></Center>
    </BookingStep>
}