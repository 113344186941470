import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../Form/Element/Button";
import './Guests.scss';
import {EventDispatcher} from "../../../../Events/Dispatcher";
import AddGuest from "./AddGuest/AddGuest";
import {ModalHelper} from "../../../../Utility/Modal/AppModal";
import ShareBooking from "../../../../Bookings/Elements/ShareBooking/ShareBooking";
import EditGuest from "./EditGuest/EditGuest";
import cx from 'classnames';
import Notice from "../../../../Utility/Notice/Notice";
import {Api} from "../../../../Api/Api";
import {NotificationManager} from "../../../../Notifications/NotifcationManager";
import {BookingData} from "../../../../Bookings/Data/BookingData";
import Loading from "../../../../Utility/Loading/Loading";

export default function Guests(props) {
    let guests = props.booking.guests.data.filter((guest) => {
        return guest.customer.data.customer_id !== props.booking.lead_booker_id;
    });
    let host = props.booking.guests.data.find((guest) => {
        return guest.customer.data.customer_id === props.booking.lead_booker_id;
    })
    const [guest_adding, setGuestAdding] = useState(false);
    return <Fragment>
        <div className="guest-management">
            <h2>Your Party</h2>
            {guests.length === 0 ? <div className="no-guests">
                <p className="no-guests--title">It looks like you haven't invited any guests to your party!</p>
                <p>Click add a guest or share the link with your friends to add them to the your booking.</p>
            </div> : null}
            <div className="guests">
                {host ? <button className="guests__guest guest__guest--host" onClick={(e) => {
                    if (props.booking.cancelled === false) {
                        EventDispatcher.dispatch('open-modal', {
                            content: <EditGuest guest={host} booking={props.booking} host={true}/>,
                            style: 'full-page'
                        });
                    }
                }}>
                    <span className="guest__guest--name">{host.customer.data.full_name}</span>
                    <span
                        className={cx('guest__guest--indicator', 'status--' + host.status_class)}>{host.status_text}</span>
                </button> : null}
                {guests.map((guest) => {
                    let display_name = guest.customer.data.full_name.trim();
                    let className = 'guest__guest--name';
                    if (guest.accepted === false && guest.customer.data.email) {
                        display_name = guest.customer.data.email;
                    }
                    if (!display_name) {
                        className = className + 'guest__guest--name no-name';
                        display_name = 'No Name';
                    }
                    return <button key={guest.id} className="guests__guest" onClick={(e) => {
                        if (props.booking.cancelled === false) {
                            EventDispatcher.dispatch('open-modal', {
                                content: <EditGuest guest={guest} booking={props.booking}/>,
                                style: 'full-page'
                            })
                        }
                    }}>
                        <span className={className}>{display_name}</span>
                        <span
                            className={cx('guest__guest--indicator', 'status--' + guest.status_class)}>{guest.status_text}</span>
                    </button>
                })}
            </div>
            {props.booking.guests.data.length < props.booking.people && !props.booking.cancelled ?
                <div className="add-guest-buttons">
                    <Button text="Add A Guest" name="add-guest" onClick={() => {
                        ModalHelper.openModal(<AddGuest booking={props.booking}/>, 'full-page');
                    }}/>
                    <Button text="Share a link" name="share-link" variant="outline" onClick={() => {
                        ModalHelper.openModal(<ShareBooking booking={props.booking}/>, 'fix-bottom');
                    }}/>
                </div> : null}
            {!host && (props.booking.guests.data.length < props.booking.people && !props.booking.cancelled) ? <Notice name="host-not-added">
                {guest_adding ? <Loading/> : <Fragment>
                    You are not added this this booking as a guest. Do you want to add yourself to the guest list?
                    <Button text="Yes" name="add-to-list" variant="text" onClick={() => {
                        setGuestAdding(true);
                        Api.post('rest/booking/' + props.booking.id + '/guest', {
                            email: BookingData.user.email,
                            admin: true
                        }).then((response) => {
                            if (response.ok) {
                                NotificationManager.showSuccess('Guest has been added to booking');
                                EventDispatcher.dispatch('booking-update');
                            }
                        });
                    }}/>
                </Fragment>}
            </Notice> : null}
        </div>
    </Fragment>
}

Guests.propTypes = {
    booking: PropTypes.object.isRequired
}