import React, {Component, Fragment} from 'react';
import _ from "lodash";
import './TimeSelect.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import {BookingData} from "../../Data/BookingData";
import {Settings} from "../../../Settings/Settings";
import TimeSelectButton from "../../../Form/Element/TimeSelectButton";
import Notice from "../../../Utility/Notice/Notice";
import IntroText from "../../../Utility/IntroText/IntroText";
import FormElementSelect from "../../../Form/Element/Select";
import Button from "../../../Form/Element/Button";
import Center from "../../../Utility/Center/Center";
import FallbackToCallbackButton from '../Callback/FallbackToCallbackButton'
import cx from "classnames";

class TimeSelect extends Component {
    constructor(props) {
        super(props);
        var slots = props.booking.all_slots.data;
        slots.sort(function (slotA, slotB) {
            if (moment(slotA.full_date).isBefore(moment(slotB.full_date))) {
                return -1;
            }
            if (moment(slotB.full_date).isBefore(moment(slotA.full_date))) {
                return 1;
            }

            return 0;
        })

        let chunks = _.chunk(slots, 12);
        let selected = 0;
        let hours = [];
        for (let chunk in chunks) {
            let found = false;
            for (let hunk in chunks[chunk]) {
                let slot = chunks[chunk][hunk]
                let hour = parseInt(slot.time.substr(0, 2));
                if (hours.indexOf(hour) === -1) {
                    hours.push(hour);
                }
                if (slot.time === props.booking.time) {
                    found = true;
                }
            }
            if (found === true) {
                selected = chunk;
            }
        }
        if (selected == 0 && slots.length > 0 && props.booking.requested_time !== null) {
            let target_hour = parseInt(props.booking.requested_time.substr(0, 2));
            const closest = hours.reduce((a, b) => {
                return Math.abs(b - target_hour) < Math.abs(a - target_hour) ? b : a;
            });
            for (let chunk in chunks) {
                let found = false;
                for (let hunk in chunks[chunk]) {
                    let slot = chunks[chunk][hunk]
                    if (parseInt(slot.time.substr(0, 2)) <= closest && slot.available) {
                        found = true;
                    }
                }
                if (found === true) {
                    selected = chunk;
                }
            }
        }
        this.state = {
            selected: parseInt(selected),
            parts: chunks,
            time: props.booking.requested_time ? props.booking.requested_time : '19:00',
            day_parts: [],
            selected_day_part: {},
            selected_day_part_id: null,
            show_full_time_grid: false,
            is_fallback_form_open: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.booking.all_slots) !== JSON.stringify(this.props.booking.all_slots)) {

            this.populateDayPartSlots()

            let props = this.props;
            let chunks = _.chunk(this.props.booking.all_slots.data, 12);
            let selected = 0;
            for (let chunk in chunks) {
                let found = false;
                for (let hunk in chunks[chunk]) {
                    let slot = chunks[chunk][hunk]
                    if (slot.time === props.booking.time) {
                        found = true;
                    }
                }
                if (found === true) {
                    selected = chunk;
                }
            }
            this.setState({selected: parseInt(selected), parts: chunks});
        }
    }

    componentDidMount() {
        this.populateDayPartSlots()
    }

    populateDayPartSlots() {
        let day_parts = [];
        let booking_type_category, booking_type = null;
        let props = this.props;
        if (props.booking.booking_type_category_id) {
            booking_type_category = BookingData.getBookingCategoryList().find(function (btc) {
                return btc.id === props.booking.booking_type_category_id
            })
        }
        if (booking_type_category === null || booking_type_category.day_parts.data.length === 0) {
            booking_type = props.booking.available_types.data.find(function (bt) {
                return bt.id === props.booking.party_type;
            })
            if (booking_type !== null && booking_type.day_parts.data.length !== 0) {
                day_parts = booking_type.day_parts.data;
            }
        } else {
            day_parts = booking_type_category.day_parts.data;
        }

        let selected_day_part_id = null;
        let selected_day_part = [];
        if (day_parts.length > 0) {
            let earliest_booking = moment().add(30, 'minutes').format("YYYY-MM-DD HH:mm:ss")
            day_parts.forEach(function (day_part) {
                if (day_part.overrides.data.length > 0) {
                    day_part.overrides.data.forEach(function (override) {
                        if (override.bar_id === this.props.booking.bar.data.id) {
                            day_part['part_name'] = override.part_name;
                            day_part['start_time'] = override.start_time;
                            day_part['end_time'] = override.end_time;
                        }
                    }.bind(this))
                }
                day_part['slots'] = [];

                let day_part_start = moment(this.props.booking.date + ' ' + day_part.start);
                let day_part_end = moment(this.props.booking.date + ' ' + day_part.end);

                // If a day part ends in the early hours (arbitrarily defined as before 8am) ensure it
                // is tomorrow for later comparison
                if (day_part_end.isSameOrBefore(moment(this.props.booking.date + ' ' + '08:00:00'))) {
                    day_part_end.add(1, 'day');
                }

                // Populate our day parts with correct slots
                this.props.booking.all_slots.data.forEach(function (slot) {
                    let slot_time = moment(slot.full_date)
                    // Ensure slots are at least 30 minutes in the future
                    if (slot_time.isAfter(earliest_booking)) {
                        if (slot_time.isSame(day_part_start) || slot_time.isBetween(day_part_start, day_part_end)) {
                            day_part['slots'].push(slot);
                        }
                    }
                })

                if (day_part.id === selected_day_part) {
                    selected_day_part = day_part
                }
            }.bind(this))

            let locally_saved_day_part_retrieved = false;
            day_parts.forEach(function (day_part, index) {
                // We must have a selected day part so grab the first, this is the last resort and should be overridden later
                if (index === 0) {
                    selected_day_part = day_part
                }

                // Get a more suitable day part with slots
                if (day_part.slots.length > 0) {

                    // Only continue trying to find a suitable day part if we don't have a locally saved day part with slots
                    if (!locally_saved_day_part_retrieved) {
                        // If our fallback selected_day_part has no slots, replace with the first day part with slots
                        if (selected_day_part.slots.length === 0) {
                            selected_day_part = day_part
                            selected_day_part_id = day_part.id
                        // If we have a selected day part saved and it has slots, return it and stop searching
                        } else if (parseInt(window.localStorage.getItem('selected_day_part')) === day_part.id) {
                            selected_day_part = day_part
                            selected_day_part_id = day_part.id
                            locally_saved_day_part_retrieved = true
                        // If we don't have one saved and there's a CMS defined default, return that
                        } else if (day_part.is_default) {
                            selected_day_part = day_part
                            selected_day_part_id = day_part.id
                        }
                    }
                }
            })

            day_parts.sort(function (da, db) {
                if (moment(da.start, 'HH:mm').isSame(moment(db.start, 'HH:mm'))) {
                    return 1;
                }
                return moment(da.start, 'HH:mm').isBefore(moment(db.start, 'HH:mm')) ? -1 : 1
            })
        }

        this.setState({
            day_parts: day_parts,
            selected_day_part_id: selected_day_part_id,
            selected_day_part: selected_day_part,
            show_full_time_grid: booking_type_category !== null && booking_type_category.show_full_time_grid
        })
    }

    changeDayPart(day_part_id) {
        let new_day_part = this.state.day_parts.find(function (day_part) {
            return day_part.id === day_part_id;
        })

        window.localStorage.setItem('selected_day_part', new_day_part.id);

        this.setState({selected_day_part: new_day_part, selected_day_part_id: new_day_part.id})
    }

    render() {
        let slots = this.state.parts[this.state.selected];
        if (!slots || slots.length === 0) {
            return <Fragment>
                {this.props.unavailable_message ? <Fragment>
                        <Notice name="fully-booked">{this.props.unavailable_message}</Notice>
                        {this.props.show_alternative ? <Fragment>
                            <FormElementSelect name="date" placeholder="Alternative Date"
                                               values={this.props.booking.alternative_dates.map(function (date) {
                                                   return {
                                                       key: date,
                                                       value: moment(date, 'YYYY-MM-DD').format('dddd Do MMM')
                                                   }
                                               })} onChange={(e) => {
                                BookingData.update({date: e.target.value});
                            }} includeBlankOption={true} blankOptionText=" - Alternative Date - "/>
                        </Fragment> : null}
                      <FallbackToCallbackButton booking={this.props.booking}/>
                    </Fragment> :
                    <Center>
                        <Notice name="no-availability" variant="error">
                            {this.props.booking.party_type === 27 && this.props.booking.no_people < this.props.booking.min_covers ? <>
                                Got a gift/experience voucher or looking to book a Masterclass
                                for fewer people? We run Shared Masterclasses on certain dates
                                each month, click the button below and we will give you a call
                                to let you know when the next session is!
                            </> : <>
                                Sorry, we don't have any availability
                                on {moment(this.props.booking.booking_date).format(Settings.date_formats.full_date)}.
                                {this.props.booking.can_fallback ?
                                    <Fragment>Do you want to submit an enquiry instead?</Fragment> : null}
                            </>}
                        </Notice>
                        <FallbackToCallbackButton booking={this.props.booking}/>
                    </Center>}
            </Fragment>
        }
        let date = moment(this.props.booking.date);
        if (this.props.booking.can_cater_for === false) {
            if (this.props.booking.can_fallback) {
                return <Notice variant="warning" name="not-available">
                    <Fragment>
                        <p>Sorry, we can't cater for a booking of this size automatically. Do you want to continue with
                            an
                            enquiry and we'll see if we can sort it out for you?</p>
                        <Button text="Yes" name="fallback" onClick={() => {
                            BookingData.update({step: 99});
                        }}/>
                        <Button variant="outline" onClick={() => {
                            BookingData.update({step: 1});
                        }} text={<Fragment><i className="fa fa-arrow-left"/> Go Back</Fragment>} name="go-back"/>
                    </Fragment>
                </Notice>
            }
            return <Fragment>
                <Notice variant="warning" name="not-available">
                    <Fragment>
                        <p>Sorry, we can't cater for a booking of this size automatically.</p>
                        <p>We may be able to sort something if you drop the bar a line
                            on <a
                                href={'mailto:' + this.props.booking.bar.data.party_booking_email}>{this.props.booking.bar.data.party_booking_email}</a>
                        </p>
                    </Fragment>
                </Notice>
            </Fragment>
        }
        let has_available = false;
        this.props.booking.all_slots.data.map((slot) => {
            if (slot.available) {
                has_available = true;
            }
            return slot;
        });
        if (this.props.booking.before_cutoff) {
            return <Fragment>
                <Notice variant="warning" name="not-available">
                    <Fragment>
                        <p>Sorry, that’s a bit too close to the booking date and time for us to automatically book this
                            in for you. You can submit an enquiry by clicking “Enquire now” and one of our team will get
                            in touch as soon as they can to let you know if they have the space to accommodate you and
                            your pals!</p>
                        {this.props.booking.can_fallback ? <Fragment>
                            <p>Do you want to submit an enquiry instead?</p>
                            <Button text="Enquire now" name="submit-enquiry" onClick={() => {
                                BookingData.update({step: 99});
                            }}/>
                        </Fragment> : <Fragment>
                            <p>We do reserve a limited amount of tables for walk-ins if you're feeling lucky?</p>
                        </Fragment>}
                    </Fragment>
                </Notice>
            </Fragment>
        }
        if (has_available === false) {
            if (this.props.show_alternative && this.props.booking.alternative_dates.length > 0) {
                return <Fragment>
                    <Notice name="fully-booked">{this.props.unavailable_message}</Notice>
                    <Fragment>
                        <FormElementSelect name="date" placeholder="Alternative Date"
                                           values={this.props.booking.alternative_dates.map(function (date) {
                                               return {
                                                   key: date,
                                                   value: moment(date, 'YYYY-MM-DD').format('dddd Do MMM')
                                               }
                                           })} onChange={(e) => {
                            BookingData.update({date: e.target.value});
                        }} includeBlankOption={true} blankOptionText=" - Alternative Date - "/>
                    </Fragment>
                </Fragment>
            } else if (this.props.allow_fallback === false) {
                return <Fragment>
                    <Notice name="fully-booked" variant="warning">So turns out, we are really popular on this date and
                        don’t have any tables left. Have a look below at alternative dates that are available or you can
                        pop by on the day to see if we have any walk-in tables free (these are on a first come, first
                        served basis).</Notice>
                </Fragment>
            }
            return <Fragment>
                <Notice variant="warning" name="not-available">
                    <Fragment>
                        <p>Sorry, we are fully booked for tables on
                            on {moment(this.props.booking.booking_date).format(Settings.date_formats.full_date)}</p>
                        <p>But we'd love to see you and so are keeping some tables open for walk-in groups so you can
                            always pop by on the day to see if one is free (walk-in tables are on a first come, first
                            served basis).
                        </p>
                    </Fragment>
                </Notice>
                {this.props.fallback_form ? <Fragment>
                    {this.state.is_fallback_form_open
                        ? this.props.fallback_form
                        : <FallbackToCallbackButton booking={this.props.booking} handleClick={() => this.setState({is_fallback_form_open: true})}/>}
                </Fragment> :
                    <FallbackToCallbackButton booking={this.props.booking}/>}
            </Fragment>
        }
        return <Fragment>
            {this.state.day_parts.length > 0 ? <Fragment>
                <div className="slot-holder">
                    <div className="time-slot-holder clearfix" id={this.props.booking.time ? 'has-selected-time' : ''}>
                        {!this.state.show_full_time_grid && <div className="day-part-selector">
                            {this.state.day_parts.map(function (day_part) {
                                var day_part_name = day_part.name;
                                if (day_part.overrides.data.length > 0) {
                                    day_part.overrides.data.forEach(function (override) {
                                        if (override.bar_id === this.props.booking.bar.data.id) {
                                            day_part_name = override.part_name
                                        }
                                    }.bind(this))
                                }
                                return <span
                                    key={day_part.id}
                                    className={cx('btn', {
                                        'btn--outline': day_part.id !== this.state.selected_day_part.id,
                                        'btn--disabled': day_part.slots.length === 0
                                    })}
                                    onClick={() => day_part.slots.length > 0 && this.changeDayPart(day_part.id)}
                                >{day_part_name}</span>
                            }.bind(this))}
                        </div>}
                        {this.state.selected_day_part.slots.map(function (slot) {
                            if (slot.available) {
                                return <TimeSelectButton text={slot.time} stacked={this.props.stacked} key={slot.time}
                                                         additional={<span>⚡ Book now</span>}
                                                         active={true} disabled={this.props.updating}
                                                         selected={this.props.booking.time === slot.time}
                                                         onClick={() => BookingData.update({time: slot.time, step: 3})}
                                />
                            }
                            if (this.props.booking.can_fallback) {
                                return <TimeSelectButton key={slot.time} text={slot.time} stacked={this.props.stacked}
                                                         disabled={false}
                                                         onClick={() => {
                                                             BookingData.update({ step: 99 })
                                                         }}
                                                         additional={<span>&#128231; &nbsp;Let's talk</span>}
                                                         active={false}
                                                         selected={this.props.booking.time === slot.time}
                                                         hasFallback={true}
                                />
                            }
                            return <TimeSelectButton key={slot.time} text={slot.time} stacked={this.props.stacked}
                                                     disabled={true}
                                                     additional={<span>Unavailable</span>}
                                                     active={false}
                                                     selected={this.props.booking.time === slot.time}
                            />
                        }.bind(this))}
                    </div>
                </div>
            </Fragment> :
            <div className="slot-holder">
                <div className="time-slot-holder clearfix">
                    {slots.map((slot) => {
                        if (slot.available) {
                            return <TimeSelectButton text={slot.time} stacked={this.props.stacked} key={slot.time}
                                                     additional={<span>⚡ Book now</span>}
                                                     active={true} disabled={this.props.updating}
                                                     onClick={() => BookingData.update({time: slot.time, step: 3})}
                            />
                        }
                        return <TimeSelectButton key={slot.time} text={slot.time} stacked={this.props.stacked}
                                                 disabled={true}
                                                 additional={this.props.show_price ? Settings.currencyFormatter.format(slot.price) : null}
                                                 active={false}/>
                    })}
                </div>
                <div className="time-nav">
                    <div className="time-nav--left">
                        {this.state.parts.length > 1 ?
                            <button disabled={this.state.selected === 0 || this.props.updating}
                                    onClick={() => this.setState({selected: this.state.selected - 1})}>
                                <i className="fa fa-angle-left"/> View Earlier</button> : <span>&nbsp;</span>}
                    </div>
                    <div className="time-nav--right">
                        {this.state.parts.length > 1 ?
                            <button
                                disabled={this.state.selected === this.state.parts.length - 1 || this.props.updating}
                                onClick={() => this.setState({selected: this.state.selected + 1})}>View Later <i
                                className="fa fa-angle-right"/></button> : <span>&nbsp;</span>}
                    </div>
                </div>
        </div>}
            <FallbackToCallbackButton booking={this.props.booking}/>
        </Fragment>
    }
}

TimeSelect.propTypes = {
    show_price: PropTypes.bool,
    unavailable_message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    stacked: PropTypes.bool,
    show_alternative: PropTypes.bool,
    allow_fallback: PropTypes.bool,
    fallback_form: PropTypes.elementType
}

export default TimeSelect;